<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Re-assessment Notification</v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-app-bar>

    <div class="col-md-4 col-sm-12 col-xs-12" v-if="!loading">
      <v-form @submit.prevent="submitForm" :disabled="formLoading">
        <v-menu
          ref="menu"
          v-model="notificationTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="form.time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              class="mt-2 mb-5"
              v-model="form.time"
              label="Notification Time"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details="auto"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="notificationTimeMenu"
            v-model="form.time"
            full-width
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="notificationTimeMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(form.time)">
              OK
            </v-btn>
          </v-time-picker>
        </v-menu>

        <v-select
          outlined
          class="mb-5"
          label="Recurrence"
          hide-details="auto"
          :items="recurrences"
          persistent-placeholder
          v-model="form.recurrence"
          @change="recurrenceChange"
          :error-messages="form.$getError('recurrence')"
        ></v-select>

        <template v-if="form.recurrence == 'weekly'">
          <div class="mb-5">
            <label class="text-field-label">Day of Week</label>
            <v-btn-toggle class="mt-2" v-model="form.day">
              <v-btn
                v-for="(day, index) in daysOfWeek"
                :key="index"
                :disabled="formLoading"
              >
                {{ day }}
              </v-btn>
            </v-btn-toggle>
          </div>
        </template>

        <template v-if="form.recurrence == 'monthly'">
          <div class="mb-5">
            <label class="text-field-label">Days of Month</label>
            <v-btn-toggle class="mt-2" v-model="form.day">
              <v-container>
                <v-row>
                  <v-col
                    v-for="(day, index) in daysOfMonth"
                    :key="index"
                    :cols="2"
                  >
                    <v-btn :disabled="formLoading">
                      {{ day }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-btn-toggle>
          </div>
        </template>

        <div class="d-flex py-5 justify-end">
          <v-btn
            :loading="formLoading"
            type="submit"
            color="primary"
            width="150px"
            depressed
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Form from '@/utils/form'

export default {
  name: 'ReassessmentNotificationPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon
  },

  created() {
    this.getSettings()
  },

  data() {
    return {
      loading: false,
      formLoading: false,
      notificationTimeMenu: false,
      form: new Form({
        time: null,
        day: null,
        recurrence: 'daily'
      }),
      recurrences: [
        { text: 'Daily', value: 'daily' },
        { text: 'Weekly', value: 'weekly' },
        { text: 'Monthly', value: 'monthly' }
      ],
      daysOfWeek: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      daysOfMonth: Array.from({ length: 31 }, (_, i) => i + 1),
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    async getSettings() {
      this.loading = true
      this.$api
        .get(`settings/notification/reassessment`)
        .then(response => {
          this.form = new Form(response.data.data)
        })
        .finally(() => (this.loading = false))
    },

    submitForm() {
      this.formLoading = true
      this.$api
        .post(`settings/notification/reassessment`, this.form.$data())
        .then(() => this.showSnackbar('Successfully updated!', 'success'))
        .finally(() => (this.formLoading = false))
    },

    recurrenceChange(val) {
      if (val === 'monthly') {
        this.form.day = [1]
      }
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>
